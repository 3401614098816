.resevo-logo{
    margin-top: 32px;
    margin-bottom: 35px;
}
.login-typography{
    margin-bottom: 0!important;
    color: #4D4D4D;
}
.login-typography-body2{
    padding-bottom: 32px!important;
    opacity: 60%;
}
.login-button{
    width: calc(100% - 64px);
    margin-bottom: 20px!important;
    margin-top: 35px!important;
    margin-right: 32px!important;
    margin-left: 32px!important;
}