.splash-card-container {
  min-height: 410px;
  height: fit-content;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  min-width: 377px;
  width: 377px;
  gap: 12px;
  flex-direction: column;

  .MuiButton-startIcon {
    margin-left: -8px;
  }

  .logo-container {
    width: 86%;
    margin: 32px auto 0;
  }

  .header {
    margin: 38px auto 0;
    width: 86%;
    text-align: center;
  }

  .user-name {
    width: 86%;
    margin: 0 auto;
    text-align: center;
    overflow-wrap: break-word;
  }

  .divider {
    width: 86%;
    margin: 21px auto 0;
    border: 1px solid #e0e0e0;
  }

  .help-text {
    width: 86%;
    margin: 15px auto 0;
  }

  .button-container {
    width: 86%;
    margin: 15px auto 0;
  }

  .button-container-bottom {
    width: 86%;
    margin: 15px auto 32px;
  }
}
