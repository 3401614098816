.error-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15%;
}
.error-page-paper {
  background: #eef0f3;
  height: 100%;
  width: 100%;
}
.text-404-head {
  font-style: normal;
  font-weight: 900;
  font-size: 160px;
  line-height: 206px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  margin: 0px;
  color: #bcc4d0;
}
.error-404-message {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  /* or 120% */
}
.error-text-content.MuiGrid-root {
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-page-divider {
  border: 1px solid #9ba6b8;
  padding-bottom: 32px;
  width: 80%;
}
