.external-background-home-page {
  background-color: #eef0f3;
  text-align: center;
  overflow: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .external-login-container {
    margin: 0 auto;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .external-paper-login {
    width: 420px;
    margin-bottom: 24px;
    box-shadow: none !important;
    border-radius: 12px !important;
    height: 560px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .adv-paper-login {
    box-shadow: none !important;
    overflow: hidden;
    border-radius: 12px !important;
    height: 560px;
    width: 796px;
  }

  .blue-row {
    position: relative;
    bottom: 0;
    background-color: #0047ba;
    color: #fff;
    width: calc(100% - 50px);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: left;
  }

  .resevo-main-header-container {
    position: fixed;
    width: calc(100% - 2rem);
    .resevo-main-header-container-content {
      background-color: white;
      border-radius: 0 0 10px 10px;
      box-shadow: 0 2px 7px lightgrey;
      height: 52px;

      .MuiGrid-root {
        justify-content: space-between;
      }

      .gnv-logo-header {
        img {
          height: 32px;
        }
      }

      .select-containerCus {
        width: 100%;
      }

      .MuiInputBase-root > .MuiSelect-select {
        text-align: start;
      }
    }
    .resevo-main-header-container-content:first-child {
      padding: 4px 8px;
    }
  }

  .footer-section {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    width: 1192px;
    height: 40px;
    margin-bottom: 24px;
    .footer-link {
      text-decoration: none;
      color: #000;
      margin-right: 5px;
    }

    .footer-container {
      background-color: #f8f9fa;
      border-radius: 10px;
      margin-top: 20px;
    }

    .footer-second-section,
    .footer-first-section {
      text-align: left;
    }

    p {
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.4px;
      font-size: 12px;
    }
  }
}
