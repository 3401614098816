.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  width: 100%;
  height: 100%;
  display: block;
}

.carousel-button {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px !important;
  transition: background-color 0.3s ease !important;
  width: 25px;
  height: 25px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  &.left {
    left: 0;
    margin-left: 8px;
  }

  &.right {
    right: 0;
    margin-right: 8px;
  }
}
