.internal-background-home-page {
  background-image: url("../../static/images/backgroundLogin.jpg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  text-align: center;
}
.internal-paper-login {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;
  box-shadow: none !important;
  border-radius: 12px !important;
}
