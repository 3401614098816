$GNV-blue-color: #0047ba; // --gnv-palette-primary-main
$GNV-deep-blue-color: #0d254c; // --gnv-palette-primary-dark
$black: #000000; // not found
$backroung-selected-open: #064477; // not found
$black-30: rgba(0, 0, 0, 0.87); // --gnv-palette-text-primary
$gap: 8px;

:root {
  // Colors
  --black-30: $black-30; // --gnv-palette-text-primary
  --Light-Primary-Contrast: #fff; // --gnv-palette-primary-contrastText
  --black: $black; //not found
  --color-grey: #e0e0e0; // --gnv-palette-grey-300
  --color-purple: #673ab7; // --gnv-palette-deep-purple-500
  --danger: #d32f2f; // --gnv-palette-error-main
  --GNV-blue-color: $GNV-blue-color; // --gnv-palette-primary-main
  --GNV-dark: $backroung-selected-open;
  --GNV-deep-blue-color: $GNV-deep-blue-color; // --gnv-palette-primary-dark
  --green: #2e7d32; // --gnv-palette-success-main
  --green-2: #388e3c; // --gnv-palette-green-700
  --grey-800: #424242; // not found
  --grey-dark: rgba(0, 0, 0, 0.26); // --gnv-palette-action-disabled-26
  --grey-light: #fafafa; // --gnv-palette-grey-50
  --grey: rgba(0, 0, 0, 0.12); // --gnv-palette-action-disabled-background-12
  --shadow-color: #e0e0e0; //--gnv-palette-grey-300
  --text-disabled: rgba(0, 0, 0, 0.38); // --gnv-palette-text-disabled
  --white: #ffffff; // --gnv-palette-background-paper or --gnv-palette-primary-contrastText or --gnv-palette-common-white
  --yellow-light: #fff9c4; // --gnv-palette-yellow-100
  --color-pink: #f5a0f5; //  changed to #f48fb1 --gnv-palette-pink-200
  --color-deep-purple: #d1c4e9; // --gnv-palette-deep-purple-100
  // Sizes
  --fixed-header-height: 3.25rem;
  --fixed-footer-height: 3.625rem;
}
