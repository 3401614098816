.user-modal-dialog {
  .MuiDialogContent-root {
    display: flex;
    min-width: 540px;
  }
  .setting-modal-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-right: 1px solid #E4E4E4;
  }
}
