.splash-page-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 84px;

  .splash-page-left-section {
    flex-basis: 35%;
    display: flex;
    justify-content: flex-end;
  }

  .splash-page-right-section {
    flex-basis: 65%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1024px;
  }

  .splash-page-right-section-adv {
    flex-basis: 65%;
    height: 100%;
    overflow: hidden;
    max-width: 1089px;
    background-image: url("../../static/images/splash-page-adv.jpg");
    background-position: top right;
  }

  .splash-image-adv {
    width: 100%;
    height: 100%;
  }

  .splash-image {
    width: 100%;
    height: auto;
    min-height: 380px;
  }
}
