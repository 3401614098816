@import "./common";
html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}
body {
  margin: 0;
}
.logo {
  max-width: 100px;
}

.custom-drop-down-menu-item {
  height: 28px;
  font-size: calc($gap * 1.5) !important;
}

.custom-drop-down-menu-item .MuiOutlinedInput-input div {
  font-size: calc($gap * 1.5) !important;
}

.page-container {
  padding: calc($gap * 2);
  height: 100%;
  display: flex;
  flex-flow: column;
}

.table-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $gap;
  flex: 0 1 auto;
}

.fit-height {
  flex: 1 1 auto;
}

.table-paper {
  display: flex;
  flex-flow: column;
}

.table-row-content {
  height: 100%;
  flex-grow: 1;
}

.MuiDataGrid-columnHeader {
  background-color: var(--gnv-palette-grey-50);
}

//Table Rows background color
//yellow
.yellow-row-background {
  background: var(--gnv-palette-yellow-50);
}
.MuiDataGrid-root .MuiDataGrid-row.yellow-row-background:hover {
  background: var(--gnv-palette-yellow-50);
  cursor: pointer;
}
//pink
.pink-row-background {
  background: var(--gnv-palette-pink-50);
}
.MuiDataGrid-root .MuiDataGrid-row.pink-row-background:hover {
  background: var(--gnv-palette-pink-50);
  cursor: pointer;
}

.grey-row-background {
  background: var(--gnv-palette-yellow-50);
}
.MuiDataGrid-root .MuiDataGrid-row.grey-row-background:hover {
  background: var(--gnv-palette-yellow-50);
  cursor: pointer;
}

//light purple
.light-purple-row-background {
  background: var(--gnv-palette-deep-purple-50);
}
.MuiDataGrid-root .MuiDataGrid-row.light-purple-row-background:hover {
  background: var(--gnv-palette-deep-purple-50);
  cursor: pointer;
}

//white deleted row
.white-row-background {
  background: var(--gnv-palette-background-paper);
  color: var(--gnv-palette-text-disabled);
}
.MuiDataGrid-root .MuiDataGrid-row.white-row-background:hover {
  background: var(--gnv-palette-background-paper);
  color: var(--gnv-palette-text-disabled);
  cursor: default;
}

// table change row color on selection
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.white-row-background,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.light-purple-row-background,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.grey-row-background,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.pink-row-background,
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected.yellow-row-background {
  background: var(--gnv-palette-info-shades-190p) !important;
  color: var(--gnv-palette-text-primary);
  box-shadow: inset 0px -10px 0px -7px var(--gnv-palette-lightblue-400),
    inset 0px 9px 0px -7px var(--gnv-palette-lightblue-400);
}
.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background: var(--gnv-palette-info-shades-190p) !important;
  box-shadow: inset 0px -10px 0px -7px var(--gnv-palette-lightblue-400),
    inset 0px 9px 0px -7px var(--gnv-palette-lightblue-400);
}

// HOVER ROWS
/*.MuiDataGrid-root .MuiDataGrid-row:hover .MuiDataGrid-cell {
  border-bottom: 1px solid rgba(224, 224, 224, 0);
}*/

.MuiDataGrid-root .MuiDataGrid-row:hover {
  box-shadow: inset 0px -10px 0px -7px var(--gnv-palette-grey-A400),
    inset 0px 9px 0px -7px var(--gnv-palette-grey-A400);
}

.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  outline-width: inherit;
  outline-offset: inherit;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

/** Form button container  **/
.frm-button-container {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
  button {
    margin-right: 8px;
  }
}

.modal-form-btn {
  min-width: inherit;
  font-weight: bold;
  height: 28px;
  width: 5px;
}

.containerCus {
  input[readonly] {
    background-color: var(--gnv-palette-grey-100);
    font-weight: 500;
  }
  &.bold-field input {
    font-weight: bold;
  }
}

// readOnly select MuiInput
.select-containerCus .MuiInputBase-readOnly {
  background-color: var(--gnv-palette-grey-100);
}

//DataGrid with even odd style
.MuiDataGrid-root.even-odd-style {
  font-size: 12px;
  font-weight: 400;
  background-color: var(--gnv-palette-background-paper);
  .MuiDataGrid-row:nth-child(even) {
    background-color: var(--gnv-palette-grey-300);
  }
}

//DataGrid with custom separators styles
.MuiDataGrid-root.separators-style {
  & .MuiDataGrid-columnHeader {
    border-right: solid 1px var(--gnv-palette-grey-300);
    color: var(--gnv-palette-common-black);
  }

  & .MuiDataGrid-cell:not(:last-child) {
    border-right: solid 1px var(--gnv-palette-grey-300);
    display: table-row-group;
  }
  & .MuiDataGrid-columnSeparator {
    opacity: 0 !important ;
  }
}

.MuiDataGrid-root .MuiDataGrid-row.blue-text-row {
  color: var(--gnv-palette-primary-main);
}

.side-modal-container {
  form {
    height: 84vh;
    overflow-y: auto;
    padding-top: 8px;
    padding-left: 2px;
  }

  @media screen and (max-height: 900px) {
    form {
      height: 80vh;
    }
  }

  @media screen and (min-height: 500px) and (max-height: 899px) {
    form {
      height: 75vh;
    }
  }

  .frm-button-container {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 20px;
    position: fixed;
    bottom: 2px;
    background-color: var(--gnv-palette-background-paper);
    width: 100%;
    height: 44px;
    z-index: 50;
    box-shadow: 2px 0px 15px 5px var(--gnv-palette-shadows-footer);
    padding-left: 16px;
    margin-left: -17px;
  }
}

/* STEPPER FORM STYLES */
.stepper-form-modal {
  .side-modal-header-container {
    padding: 16px;
    background-color: var(--gnv-palette-background-paper);
  }
  .side-modal-container {
    padding: 0px;
    background-color: var(--gnv-palette-background-modal-grey);

    .stepper-form {
      background-color: var(--gnv-palette-background-modal-grey);
      padding: 16px;
    }

    .frm-button-container {
      padding: 10px 16px;
      background-color: var(--gnv-palette-background-modal-grey);
      margin-left: 0;
    }
  }

  .MuiStepper-root.MuiStepper-horizontal {
    width: 80%;
    padding: 10px 10px 0;
    margin-bottom: 10px;
  }
  .box-color {
    width: 50%;
    height: 28px;
    border: 1px solid #212121;
    border-radius: 5px;
  }
  .containerCus {
    input {
      background-color: var(--gnv-palette-background-paper);
    }

    input[readonly] {
      background-color: var(--gnv-palette-grey-100);
    }

    &.bold-field input {
      font-weight: bold;
    }
  }
}

/* STYLE SCROLLBAR WHEREVER IT APPEARS */

/* Track */
::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar-track {
  background-color: var(--gnv-palette-background-paper);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--gnv-palette-grey-300);
  border-radius: 10px;
  border: 3px solid var(--gnv-palette-background-paper);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb;
}

@import "~@gnv/resevo-react-lib/dist/style.css";
